.ProjectContainer {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  justify-items: center;
  margin-bottom: 50px;
}
.ProjectWrapper {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
  transition: all 0.25s ease 0s;
  top: 0px;
  border-radius: 8px;
}
.project_inner {
  display: flex;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}

.project_textContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
  width: calc(100% - 200px);
}
.project_desc {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  font-size: 0.9rem;
}

.project_imageContainer {
  margin: auto;
  width: 200px;
  height: 200px;
}
.projectImage {
  width: 200px;
  height: 200px;
  padding: 40px;
  margin-top: 0px;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  max-width: 100%;
  height: auto;
}
.ProjectTag {
  position: relative;
  height: 200px;
  top: calc(-203.5px);
}
.project_url {
  display: flex;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  float: right;
}
.project_platform {
  position: absolute;
  display: inline;
  padding: 10px 10px 10px 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  right: 0px;
  bottom: 0px;
  clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%);
  border-radius: 0px 0px 8px;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background-color: rgb(124, 55, 173);
  color: white;
}
.project_year {
  position: absolute;
  display: inline;
  padding: 10px 20px 10px 10px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  left: 0px;
  top: 0px;
  clip-path: polygon(0px 0%, 100% 0%, calc(100% - 20px) 100%, 0% 100%);
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background-color: rgb(240, 230, 246);
}
.project_link {
  transition: color 0.5s ease 0s;
  color: rgb(124, 55, 173);
  text-decoration: none;
}
.project_url_icon {
  box-sizing: border-box;
  margin: 0px 4px;
  min-width: 0px;
  font-size: 32px;
}

.react-reveal {
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 200ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: react-reveal-972111790791844-2;
}
.project__status {
  display: inline-block;
  margin: 3px 3px 3px 0;
  font-family: Inconsolata, monospace;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 2px 7px;
  border-radius: 3px;
  border: 1px solid #e8eaef;
  background: 0 0;
}

.status-green {
  background-color: #53c72b;
}
.status-default {
  background-color: #e58b5f;
}
.dot,
.dot-double {
  display: inline-block;
  border-radius: 9999px;
}
.dot {
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
}
